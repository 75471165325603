/* eslint-disable */
/*import { TimelineMax, TweenMax, Power2, Power4 } from 'gsap/umd/TweenMax';
import ScrollMagic from 'ScrollMagic';*/

// var wayPointWhoWeAreTriggered = false;

function ctaFull() {
// init controller
//     var controller = new ScrollMagic.Controller();

// create a scene

    /*function allComplete(){

    };

    var scene = new ScrollMagic.Scene({
        triggerElement: "#js-cta-waypoint",
        offset: -50	// start this scene after scrolling for 50px
    })
        .addTo(controller)
        .on("enter leave", function (e) {
            scene.destroy();
            console.log("gekkie");
            var tl = new TimelineMax({onComplete: allComplete});
            tl.set(".clw", {fill: "transparent"}).staggerFrom(".clw", 1, {
                autoAlpha: 0,
                ease: Power2.easeInOut,
                fill: "transparent",
                delay: 0,
            }, -0.005);
            tl.set(".clw", {fill: "transparent"})
        })*/

    $( '.c-cta-full__optional-link' ).on( 'click', function (e) {
        e.preventDefault();

        $( 'body' ).addClass( 'h-scroll-lock' );
        $( '.c-optional-popup__content' ).text( $( this ).siblings( '.c-cta-full__optional-popup-content' ).text() );
        $( '.c-optional-popup' ).fadeIn();

        $( '.c-optional-popup' ).addClass( 'c-optional-popup--cta-opened' );
    } );

    $( document ).on( 'click', function( event ) {
        if (
            $( event.target ).is( '.c-cta-full__optional-link' ) === false &&
            $( event.target ).is( '.c-optional-popup__container' ) === false &&
            $( '.c-optional-popup__container' ).has( event.target ).length === 0 &&
            $( '.c-optional-popup' ).hasClass( 'c-optional-popup--cta-opened' )
        ) {
            $( 'body' ).removeClass( 'h-scroll-lock' );
            $( '.c-optional-popup' ).fadeOut();

            $( '.c-optional-popup' ).removeClass( 'c-optional-popup--cta-opened' );
        }
    });

    $( '.c-optional-popup__close-btn' ).on( 'click', function () {
        $( 'body' ).removeClass( 'h-scroll-lock' );
        $( '.c-optional-popup' ).fadeOut();

        $( '.c-optional-popup' ).removeClass( 'c-optional-popup--cta-opened' );
    } );
}

// waypoint.refreshAll()

export default ctaFull;

