/* eslint-disable */
import mixitup from 'mixitup';
import mixitupMultiFilter from '../../../js/custom/mixitup/multi';
import mixitupPagination from '../../../js/custom/mixitup/pagination';

const minSizeRangeInput = document.querySelector('[name="minSize"]');
const maxSizeRangeInput = document.querySelector('[name="maxSize"]');

function setMixer(mixer) {
	var hash;
	var url;

	if (navigator.userAgent.indexOf('AppleWebKit') != -1) {
		hash = window.location.hash;
	} else {
		url = window.location.href;
		hash = url.substring(url.indexOf('#') + 1);
	}

	if (hash) {
		const filters = hash.replace('#', '').split('_');

		let page = 1;

		if (filters.length > 1 && filters[filters.length - 1].includes('page')) {
			page = filters
				.splice(-1, 1)[0]
				.split('-')
				.splice(-1, 1)[0];
		}

		// mixer.multimix({
		// 	filter: '.' + filters.join(', .'),
		// 	paginate: page
		// });

		// //make sure the dropdown has selected in its text
		// if (filters.indexOf("mix") === -1) {
		//     $('.c-filter-control-multi__label').text('SELECTED');
		//     $('.c-apartments-preview').addClass('c-apartments-preview--active');
		//
		// } else {
		//     $('.c-filter-control-multi__label').text('ALL');
		//     $('.c-apartments-preview').removeClass('c-apartments-preview--active');
		// }
	}
}

function hasClass(element, cls) {
	return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

function filter() {
	mixitup.use(mixitupPagination);
	mixitup.use(mixitupMultiFilter);

	let $pillsWrapper = $('.js-pills-wrapper');
	let $pillClone = $('.js-sample-pill')
		.clone()
		.removeClass('hide');

	const mixer = mixitup('.js-content-filter', {
		multifilter: {
			enable: true,
			logicWithinGroup: 'or',
			logicBetweenGroups: 'and'
		},
		pagination: {
			limit: 399 // impose a limit of 12 targets per page
		},
		animation: {
			duration: 400,
			nudge: true,
			reverseOut: false,
			effects: 'fade translateZ(-50px)'
		},
		load: {},
		callbacks: {
			onMixStart: function( state, futureState ) {
				$( ".c-filter__no-results" ).removeClass("c-filter__no-results--show");
			},
			onMixEnd: function(state) {
				let finalActiveFilterArray = [];
				let finalActiveFilterString = '';
				const originalActiveFilter = state.activeFilter.selector;
				
				if( originalActiveFilter.indexOf(',') >= 0 ) {
					originalActiveFilter.trim().split(',').map(originalFilter => {
						originalFilter.trim().split('.').map(filter => {
							if ( filter != '' && $.inArray( filter, finalActiveFilterArray ) < 0 ) {
								finalActiveFilterArray.push(filter);

								finalActiveFilterString += `${filter}_`;
							}
						});
					});
				} else {
					originalActiveFilter.trim().split('.').map(filter => {
						if ( filter != '' && $.inArray( filter, finalActiveFilterArray ) < 0 ) {
							finalActiveFilterArray.push(filter);

							finalActiveFilterString += `${filter}_`;
						}
					});
				}
				
				finalActiveFilterString = finalActiveFilterString.slice(0, finalActiveFilterString.length - 1);

				const countTotal = state.totalMatching;
				const activePage = state.activePagination.page ? state.activePagination.page : 1;
				const newHash = '#' + finalActiveFilterString + '_page-' + activePage;

				$pillsWrapper.empty();
				
				
				
				let pills = finalActiveFilterString.split('_').map(filter => {
					
					/* if no filter is set */
					if (filter === 'mix' || filter === 'price-all') {
						return;
					}

					let filterLabel = $(`li[data-toggle='.${filter}']`).data('label');
					
					let $newPillClone = $pillClone.clone();
					$newPillClone.find('span').text(filterLabel);

					/* remove filter hook */
					$newPillClone.on('click', function(e) {

						$( '[data-toggle=".' + filter + '"]' ).trigger( 'click' );
						
						$(this).hide();
					});

					return $newPillClone;
				});

				$pillsWrapper.html(pills);

				// $('.mixCount').html(count);
				$('.mixCountTotal').html(countTotal);
			},
			onMixClick: function(state, originalEvent) {
				// mixer.sort('name:asc')
				//reset pagination when using a filter
				if (hasClass(originalEvent.target, 'c-filter-control-multi__btn')) {
					state.activePagination.page = 1;
				}
				// $('html,body').animate({
				//     scrollTop: $(".js-content-filter").offset().top - 100
				// }, 1000);
			},
			onMixFail: function( state ) {
				$( ".c-filter__no-results" ).addClass("c-filter__no-results--show");
			}
		}
	});

	setMixer(mixer);

	$( '.c-filter__no-results-btn' ).on( 'click', function () {
		$( '.c-filter-control-multi__clear' ).trigger( 'click' );
	});

	const aptSliderClass = 'c-photo-gallery-apartment';

	$( `button[class*=${aptSliderClass}__slider-]` ).on( 'click', function() {
		let btnType = $( this ).data( 'btnType' );
		let currentSlide = $( this ).parent().siblings( `.${aptSliderClass}__slider-wrapper` ).find( `.${aptSliderClass}__slide-wrapper--active` );
		let shownSlide = {};
		let finalSlideOrder = '';

		btnType === 'prev' ? shownSlide = currentSlide.prev() : shownSlide = currentSlide.next();

		btnType === 'prev' ? finalSlideOrder = 'last-child' : finalSlideOrder = 'first-child';

		currentSlide.removeClass( `${aptSliderClass}__slide-wrapper--active` ).fadeOut();

		if ( shownSlide.length ) {
			shownSlide.addClass( `${aptSliderClass}__slide-wrapper--active` ).hide().fadeIn();
		} else {
			let finalSlide = $( this ).parent().siblings( `.${aptSliderClass}__slider-wrapper` ).children( `.${aptSliderClass}__slide-wrapper:${finalSlideOrder}` );

			finalSlide.addClass( `${aptSliderClass}__slide-wrapper--active` ).hide().fadeIn();
		}
	});
}

export default filter;
