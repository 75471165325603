/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

var selector           = '.js-hamburger';
var activeClass        = 'is-active';
var bodyActiveClass    = 'h-scroll-lock';
var headerActiveClass  = 'l-header--mob-is-active';
var navMenu            = '.js-slide-out';
var navMenuActiveClass = 'c-slide-out--active';

let isActive;
const tabbableElements = $(
    `${navMenu} a[href]:visible:not([disabled]), 
     ${navMenu} button:visible:not([disabled]),
     ${navMenu} input:visible:not([disabled]), 
     ${navMenu} select:visible:not([disabled]), 
     ${navMenu} textarea:visible:not([disabled]), 
     ${navMenu} [tabindex]:not([tabindex="-1"]):visible:not([disabled])`,
    );

export function hamburger() {
    $(selector ).click(function(e) {
        e.preventDefault();
        $(selector).toggleClass(activeClass);
        $(".js-body").toggleClass(bodyActiveClass);
        $(".js-header").toggleClass(headerActiveClass);
        $(navMenu).toggleClass(navMenuActiveClass);
    });

    // Ensuring slideout nav menu is next element focused after hamburger toggle button
    $(selector).keydown(function (e) {
        if (e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (isActive) {
                e.preventDefault();
                tabbableElements.first()[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    // Ensuring focus shifts back to hamburger toggle button when going back
    tabbableElements.first().keydown(function (e) {
        if (e.shiftKey && e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (
                isActive &&
                document.activeElement === tabbableElements.first()[0]
            ) {
                e.preventDefault();
                $(selector)[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    // Ensuring focus shifts back to hamburger toggle button after last link in slide out nav menu
    tabbableElements.last().keydown(function (e) {
        if (e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (
                isActive &&
                document.activeElement === tabbableElements.last()[0]
            ) {
                e.preventDefault();
                $(selector)[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });
}

export function hamburgerClose(){
    $(selector).removeClass(activeClass);
    $(".js-body").removeClass(bodyActiveClass);
    $(".js-header").removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
