import '../../../../static/vendors/lightgallery/js/lightgallery.min';
import '../../../../static/vendors/lg-thumbnail/lg-thumbnail.min';
import '../../../../static/vendors/lg-fullscreen/lg-fullscreen.min';

const componentClass = '.c-slide-out-apartment';
const activeComponentClass = 'c-slide-out-apartment--active';
const closeBtnClass = componentClass + '__close-btn';
const openedCloseBtnClass = 'c-slide-out-apartment__close-btn--open';
const cardApartmentClass = '.c-photo-gallery-apartment__card-link';
const multiFilterControlClass = 'c-filter-control-multi';

function clickActionOutsideAreaSlideOut( event ) {
    if (
        $( event.target ).is( componentClass ) === false &&
        $( componentClass ).has( event.target ).length === 0 &&
        $( '.lg-outer' ).has( event.target ).length === 0
    ) {
        $( componentClass ).removeClass( activeComponentClass );
        $( 'body' ).removeClass( 'h-scroll-lock' );

        if ( $( closeBtnClass ).hasClass( openedCloseBtnClass ) ) {
            $( closeBtnClass ).toggleClass( openedCloseBtnClass );
        }
    }
}

function slideOutApartment() {
    $( cardApartmentClass ).on( 'click', function( e ) {
        e.preventDefault();

        if( $( `.${multiFilterControlClass}__toggler` ).hasClass( `${multiFilterControlClass}__toggler--open` ) ) {
            $( `.${multiFilterControlClass}__toggler` ).trigger( 'click' );
        }

        if ( $( '.c-city-selector__list' ).hasClass( 'c-city-selector__list--open' ) ) {
            $( '.js-city-selector' ).trigger( 'click' );
        }

        $( 'body' ).toggleClass( 'h-scroll-lock' );

        if( $( e.target ).is( `.${multiFilterControlClass}__list` ) === false ) {
            $( `.${multiFilterControlClass}__open-button` ).removeClass( `${multiFilterControlClass}__open-button--is-open` );
            $( `.${multiFilterControlClass}__list` ).slideUp(200);

        }

        if ( !$( componentClass ).hasClass( activeComponentClass ) ) {
            const selectedApartmentId = $( this ).parent().siblings( '.c-card-apartment__info-wrapper' ).data('apartmentId');
            const currentApartmentPT = $( this ).parent().siblings( '.c-card-apartment__info-wrapper' ).data('apartmentPt');
            const currentCityName = $( this ).parent().siblings( '.c-card-apartment__info-wrapper' ).data('currentCity');
            let luxury = $( this ).closest( `[data-luxury]` );
            luxury = luxury && luxury.attr('data-luxury') === '1';

            $( `${componentClass}__dynamic-content` ).html(
                `<div class="c-slide-out-apartment__photo-gallery-loader">
                    <div class="c-slide-out-apartment__photo-gallery-loader-body">
                        <div class="c-slide-out-apartment__photo-gallery-loader-ring"></div>
                    </div>
                </div>`
            );

            $.post(
                ajax_object.ajax_url,
                {
                    action               : 'load_apartment_photo_gallery',
                    current_apartment_id : selectedApartmentId,
                    current_apartment_pt : currentApartmentPT,
                    current_city_name    : currentCityName,
                    luxury               : luxury,
                },
                function ( result ) {
                    $( `${componentClass}__dynamic-content` ).html(result);
                }
            ).done( function () {
                $( '#apartment-photos-gallery' ).lightGallery({
                    thumbnail : true,
                    download: false
                });

                $( '.js-apartment-slider-out-gallery' ).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    fade: true,
                    prevArrow: $( '.js-apartment-slider-out-gallery' ).parent().next().find( '.js-slider-prev' ),
                    nextArrow: $( '.js-apartment-slider-out-gallery' ).parent().next().find( '.js-slider-next' ),
                    asNavFor: '.js-apartment-slider-out-gallery-nav'
                });

                $( '.js-apartment-slider-out-gallery-nav' ).slick({
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    arrows: false,
                    asNavFor: '.js-apartment-slider-out-gallery',
                    dots: false,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 5,
                            }
                        },
                        {
                            breakpoint: 544,
                            settings: {
                                slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 3,
                            }
                        }
                    ]
                });

                $( `${componentClass}__dynamic-content` ).hide().fadeIn('slow');
            } );
        }

        e.preventDefault();

        $( closeBtnClass ).toggleClass( openedCloseBtnClass );
        $( componentClass ).toggleClass( activeComponentClass );

        e.stopPropagation();
    });

    $( closeBtnClass ).on( 'click', function( e ) {
        $( closeBtnClass ).toggleClass( openedCloseBtnClass );
        $( componentClass ).toggleClass( activeComponentClass );
        $( 'body' ).removeClass( 'h-scroll-lock' );

        e.stopPropagation();
    });

    $( document ).on( 'click', function( e ) {
        if( $( `${componentClass}` ).hasClass( activeComponentClass ) ) {
            clickActionOutsideAreaSlideOut( e );
        }
    });

    // This document.click event must fire on iOS Safari
    document.addEventListener('touchstart', function( e ) {
        if( $( `${componentClass}` ).hasClass( activeComponentClass ) ) {
            clickActionOutsideAreaSlideOut( e );
        }
    });
}

export default slideOutApartment;