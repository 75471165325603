/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
var Headroom = require('headroom.js');

function headerBannerLayoutCorrector(headroomOffset) {
    let bannerHeight = $('.l-header__banner').innerHeight();

    if ($(window).scrollTop() >= headroomOffset) {
        $('.l-header').css({'margin-top': 0, 'top': -bannerHeight});
        $('main').css({'margin-top': bannerHeight});
    } else {
        $('.l-header').css({'margin-top': bannerHeight, 'top': -bannerHeight});
        $('main').css({'margin-top': bannerHeight});
    }
}

function headRoom() {
    $('.c-nav__item--level-0 .c-nav__link--level-0.menu-item-type-custom').on( 'click', function ( e ) {
        e.preventDefault();
    });

    // grab an element
    var header = document.querySelector("#header");
    var btn_page = ".c-button-page";
    let activeClass = 'c-button-page--active';
    let bannerNegativeHeight = -$('.l-header__banner').innerHeight();
    let bannerHeight = $('.l-header__banner').innerHeight();
    let headroomOffset = 40;

    $(window).on('scroll', function() {
        headerBannerLayoutCorrector(headroomOffset);
    });

    $(window).on('resize', function() {
        headerBannerLayoutCorrector(headroomOffset);
    });

    $(function() {
        headerBannerLayoutCorrector(headroomOffset);
    });

    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(header, {
        offset: headroomOffset,
        tolerance: {
            up: 15,
            down: 25,
        },
        classes: {
            // when element is initialised
            initial: "l-headroom",
            // when scrolling up
            pinned: "l-headroom--pinned",
            // when scrolling down
            unpinned: "l-headroom--unpinned",
            // when above offset
            top: "l-headroom--top",
            // when below offset
            notTop: "l-headroom--not-top",
            // when at bottom of scoll area
            bottom: "l-headroom--bottom",
            // when not at bottom of scroll area
            notBottom: "l-headroom--not-bottom",
        },
        onNotTop: function () {
            $(btn_page).addClass(activeClass);
            $('.l-header').css({'margin-top': 0, 'top': bannerNegativeHeight});
        },
        onTop: function () {
            $(btn_page).removeClass(activeClass);
            $('.l-header').css({'margin-top': bannerHeight, 'top': bannerNegativeHeight});
        }
    });

    // initialise
    headroom.init();

    $( '.l-header__custom-btn--faq' ).on( 'click', function ( e ) {
        e.preventDefault();

        let pageFAQSelector = $( this ).closest( '.l-header' ).siblings( '.l-body-wrapper' ).children( '.c-faqs' );

        $( 'body, html' ).animate({
            scrollTop: pageFAQSelector.offset().top - 70
        }, 1000);
    } );

    $( '.l-header__custom-top-button' ).on( 'click', function ( e ) {
        e.preventDefault();

        let pageSelectedBlock = $($( this ).attr( 'href' ).trim());

        $( 'body, html' ).animate({
            scrollTop: pageSelectedBlock.offset().top - 70
        }, 1000);
    } );

    $( '.c-hero__optional-link' ).on( 'click', function (e) {
        e.preventDefault();

        $( 'body' ).addClass( 'h-scroll-lock' );
        $( '.c-optional-popup__content' ).text( $( this ).siblings( '.c-hero__optional-popup-content' ).text() );
        $( '.c-optional-popup' ).fadeIn();

        $( '.c-optional-popup' ).addClass( 'c-optional-popup--hero-opened' );
    } );

    $( document ).on( 'click', function( event ) {
        if (
            $( event.target ).is( '.c-hero__optional-link' ) === false &&
            $( event.target ).is( '.c-optional-popup__container' ) === false &&
            $( '.c-optional-popup__container' ).has( event.target ).length === 0 &&
            $( '.c-optional-popup' ).hasClass( 'c-optional-popup--hero-opened' )
        ) {
            $( 'body' ).removeClass( 'h-scroll-lock' );
            $( '.c-optional-popup' ).fadeOut();

            $( '.c-optional-popup' ).removeClass( 'c-optional-popup--hero-opened' );
        }
    });

    $( '.c-optional-popup__close-btn' ).on( 'click', function () {
        $( 'body' ).removeClass( 'h-scroll-lock' );
        $( '.c-optional-popup' ).fadeOut();

        $( '.c-optional-popup' ).removeClass( 'c-optional-popup--hero-opened' );
    } );
}

export default headRoom;
