function slideOutBasic() {
    let width  = $( window ).width();

    // When mobile menu is open and the user resizes screen, it closes
    $( window ).resize( function(){
        if ( $( window ).width() == width ) return;

        if ( $( '.c-slide-out' ).hasClass( 'c-slide-out--active' ) &&
            $( '.l-header' ).hasClass( 'l-header--mob-is-active' ) &&
            $( '.hamburger' ).hasClass( 'is-active' ) &&
            $( 'body' ).removeClass( 'h-scroll-lock' ) &&
            $( window ).width() <= 1124 )
        {
            $( '.c-slide-out' ).removeClass( 'c-slide-out--active' );
            $( '.l-header' ).removeClass( 'l-header--mob-is-active' );
            $( '.hamburger' ).removeClass( 'is-active' );
            $( 'body' ).removeClass( 'h-scroll-lock' );
        }
    } );

    $( '.c-slide-out--basic .menu-item-has-children' ).on( 'click', function() {
        $( this ).children( '.c-arrow-toggle' ).toggleClass( 'c-nav__arrow--active' );
        $( this ).next().slideToggle();
    });
}

export default slideOutBasic;