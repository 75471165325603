
/* eslint-disable */
function clickActionOutsideAreaFilter( event, componentClass ) {
    if (
        $( event.target ).is( `.${componentClass}` ) === false &&
        $( `.${componentClass}` ).has( event.target ).length === 0
    )
    {
        
        $( `.${componentClass}__btn--active` ).trigger('click');

        /*let filterButton = $( `.${componentClass}__toggler` );

        if ( filterButton.hasClass( `${componentClass}__toggler--open` ) ) {
            filterButton.trigger( 'click' );
        }

        if (
            $( event.target ).is( `.${componentClass}__list` ) === false &&
            $( `.${componentClass}__list` ).has( event.target ).length === 0
        )
        {
            $( `.${componentClass}__open-button` ).removeClass( `${componentClass}__open-button--is-open` );
            $( `.${componentClass}__list` ).slideUp( 200 );
        }*/
    }
}

function controlCollapsable() {
    
    const componentClassName   = 'c-filter-control-redux';
    const slideOutAptClassName = 'c-slide-out-apartment';

    $('.js-filter-controls-reset').on( 'click', function (e) {
        
        $(`.${componentClassName}__btn--active`).trigger('click');
    });

    $(`.${componentClassName}__btn`).on( 'click', function(e) {
        var $el = $(this).parents(`.${componentClassName}__content`);
        var $label = $el.parent().find(`.${componentClassName}__label`);

        //add teimout see we give mixitup enought time to add the class
        setTimeout(() => {
            if ($el.find('.mixitup-control-active').length !== 0) {
                $label.text('Selected');
            } else {
                $label.text('Filter');
            }

        }, 100);
    });

    $( window ).resize( function(){
        if ( window.innerWidth >= 992 ) {
            $( `.${componentClassName}__content` ).css( 'display', 'flex' );
        } else if ( window.innerWidth < 992 && $( `.${componentClassName}__content` ).css( 'display' ) === 'flex' ) {
            $( `.${componentClassName}__content` ).css( 'display', 'none' );

            $( `.${componentClassName}__toggler` ).removeClass( `${componentClassName}__toggler--open` );

            if( $( `.${componentClassName}__toggler` ).children( 'i' ).hasClass( 'fa-times-circle-o' ) ) {
                $( `.${componentClassName}__toggler-text` ).text( 'Filter' );
                $( `.${componentClassName}__toggler` ).children( 'i' ).removeClass( 'fa-times-circle-o' ).addClass( 'fa-filter' );
            }
        }
    } );

    $( `.${componentClassName}__toggler` ).on( 'click', function() {
        $ ( this ).toggleClass( `${componentClassName}__toggler--open` );

        $( this ).siblings( `.${componentClassName}__content` ).slideToggle();

        $( this ).children( `.${componentClassName}__toggler-text` ).text( function( index, value ){
            return value === 'Filter' ? 'Close Filter' : 'Filter';
        });

        $( this ).children( 'i' ).toggleClass( 'fa-filter fa-times-circle-o' );
    } );

    $ ( `.${componentClassName}__open-button` ).on( 'click', function( e ) {
        e.preventDefault();

        if ( $( '.c-city-selector__list' ).hasClass( 'c-city-selector__list--open' ) ) {
            $( '.js-city-selector' ).trigger( 'click' );
        }

        $( `.${slideOutAptClassName}` ).removeClass( `${slideOutAptClassName}--active` );
        $( 'body' ).removeClass( 'h-scroll-lock' );

        if ( $( `.${slideOutAptClassName}__close-btn` ).hasClass( `${slideOutAptClassName}__close-btn--open` ) ) {
            $( `.${slideOutAptClassName}__close-btn` ).toggleClass( `${slideOutAptClassName}__close-btn--open` );
        }

        if ( $( this ).hasClass( `${componentClassName}__open-button--is-open` ) ) {
            $(`.${componentClassName}__open-button`).removeClass(`${componentClassName}__open-button--is-open`);
            $(`.${componentClassName}__open-button`).siblings(`.${componentClassName}__list`).slideUp(200);
        } else {
            $( `.${componentClassName}__open-button` ).not( $( this ) ).removeClass( `${componentClassName}__open-button--is-open` );
            $( `.${componentClassName}__open-button` ).not( $( this ) ).siblings( `.${componentClassName}__list` ).slideUp(200);

            $( this ).addClass(`${componentClassName}__open-button--is-open`);

            $( this ).siblings(`.${componentClassName}__list`).slideDown(200);
        }
    } );

    $( document ).on( 'click', function( e ) {
        clickActionOutsideAreaFilter( e, componentClassName );
    });

    $('.js-item-1-btn').on( 'click', function( e ) {
        $('.js-item-1').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn').toggleClass("c-filter-control-redux__btn--active");

        // Removing all other active classes
        $('.js-item-2, .js-item-3, .js-item-4, .js-item-5, .js-item-6, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-2-btn, .js-item-3-btn, .js-item-4-btn, .js-item-5-btn, .js-item-6-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");

        // Adding the filter active class
        if ($('.js-item-1').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });

    $('.js-item-2-btn').on( 'click', function( e ) {
        $('.js-item-2').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-2-btn').toggleClass("c-filter-control-redux__btn--active");

        // Removing all other active classes
        $('.js-item-1, .js-item-3, .js-item-4, .js-item-5, .js-item-6, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-3-btn, .js-item-4-btn, .js-item-5-btn, .js-item-6-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");


        // Adding the filter active class
        if ($('.js-item-2').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });

    $('.js-item-3-btn').on( 'click', function( e ) {
        $('.js-item-3').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-3-btn').toggleClass("c-filter-control-redux__btn--active");

        // Removing all other active classes
        $('.js-item-1, .js-item-2, .js-item-4, .js-item-5, .js-item-6, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-2-btn, .js-item-4-btn, .js-item-5-btn, .js-item-6-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");

        // Adding the filter active class
        if ($('.js-item-3').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });

    $('.js-item-4-btn').on( 'click', function( e ) {
        $('.js-item-4').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-4-btn').toggleClass("c-filter-control-redux__btn--active");

        // Removing all other active classes
        $('.js-item-1, .js-item-2, .js-item-3, .js-item-5, .js-item-6, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-2-btn, .js-item-3-btn, .js-item-5-btn, .js-item-6-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");

        // Adding the filter active class
        if ($('.js-item-4').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });

    $('.js-item-5-btn').on( 'click', function( e ) {
        $('.js-item-5').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-5-btn').toggleClass("c-filter-control-redux__btn--active");

        // Removing all other active classes
        $('.js-item-1, .js-item-2, .js-item-3, .js-item-4, .js-item-6, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-2-btn, .js-item-3-btn, .js-item-4-btn, .js-item-6-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");

        // Adding the filter active class
        if ($('.js-item-5').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });
    
    $('.js-item-6-btn').on( 'click', function( e ) {
        $('.js-item-6').toggleClass("c-filter-control-redux__item--active");
        $('.js-item-6-btn').toggleClass("c-filter-control-redux__btn--active");
    
        // Removing all other active classes
        $('.js-item-1, .js-item-2, .js-item-3, .js-item-4, .js-item-5, .js-item-7').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-2-btn, .js-item-3-btn, .js-item-4-btn, .js-item-5-btn, .js-item-7-btn').removeClass("c-filter-control-redux__btn--active");
    
        // Adding the filter active class
        if ($('.js-item-6').hasClass("c-filter-control-redux__item--active")){
            $('.c-filter').addClass("c-filter--active");
        } else {
            $('.c-filter').removeClass("c-filter--active");
        }
    });

    $('.js-item-7-btn').on( 'click', function( e ) {
        // Removing all other active classes
        $('.js-item-1, .js-item-2, .js-item-3, .js-item-4, .js-item-5, .js-item-6').removeClass("c-filter-control-redux__item--active");
        $('.js-item-1-btn, .js-item-2-btn, .js-item-3-btn, .js-item-4-btn, .js-item-5-btn, .js-item-6-btn').removeClass("c-filter-control-redux__btn--active");

        // Removing the filter class
        $('.c-filter').removeClass("c-filter--active");
    });

    // Hide opened dropdowns after clicking close button
    $(`button[data-role='close-dropdown']`).on('click', function(event) {
        event.preventDefault();

        const container = $(this).closest(`.${componentClassName}__item`);
        if (!container) {
            return;
        }
        const button = container.find(`.${componentClassName}__btn`);
        if (!button) {
            return;
        }

        button.trigger('click');
    });
    

    // This document.click event must fire on iOS Safari
    /*document.addEventListener('touchstart', function( e ) {
        clickActionOutsideAreaFilter( e, componentClassName );
    });*/
}

export default controlCollapsable;