/*
 * Nav Dropdowns
 *
 * runs the functionality of clicking the arrow in the mobile menu dropdown
 */

function navMob($) {

    const selector = '.js-nav-drop';
    const activeClass = 'is-active';

    $(selector).click(function () {
        $(this).toggleClass('c-nav__toggle-wrapper--active');
        $(this).toggleClass(activeClass).next('ul').slideToggle(250);
        $('.js-plus-toggle').toggleClass('c-plus-toggle--opened');
    });
}

export default navMob;
