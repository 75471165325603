function typeWriter( i, chatText, targetSelector, animationSpeed = 50 ) {
    if ( i < chatText.length ) {
        document.querySelector( targetSelector ).innerHTML += chatText.charAt( i );

        i++;

        setTimeout( typeWriter, animationSpeed, i, chatText, targetSelector );
    }
}

$.fn.isInViewport = function() {
    let elementTop = $( '.c-rewards-program__chat-box--bottom' ).offset().top + 80;
    let elementBottom = elementTop - 160 + $( '.c-rewards-program__chat-box--bottom' ).outerHeight();
    let viewportTop = $( window ).scrollTop();
    let viewportBottom = viewportTop + $( window ).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

function showRewardsProgram() {
    let i = 0;
    let chatTopBoxText = 'Hey! I saw you’re moving. Let Smart City Help you!';
    let chatBottomBoxText = 'scity.io/98765';
    let animationSpeed = 50;
    let animationInitialized = false;

    $( window ).on( 'load scroll', function () {
        if( $( '.c-rewards-program__chat-box--bottom' ).isInViewport() && !animationInitialized ) {
            typeWriter( i, chatTopBoxText, '.c-rewards-program__chat-box--top', animationSpeed );

            setTimeout(typeWriter, 2800, i, chatBottomBoxText, '.c-rewards-program__chat-box--bottom a' );

            animationInitialized = true
        }
    });
}

export default showRewardsProgram;
