import 'slick-carousel';

let slider_dots;

function showSliderPartners() {
    $('.js-slider-partners').each(function () {
        let $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        $child_slider.not('.slick-initialized').slick({
            dots: true,
            arrows: true,
            autoplaySpeed: 5000,
            infinite: true,
            autoplay: false,
            speed: 450,
            slidesToShow: 5,
            prevArrow: $child_slider.siblings('.js-slider-prev'),
            nextArrow: $child_slider.siblings('.js-slider-next'),
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 544,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            ]
        });
    });

    $('.touchevents .flip-card').on("click",function(){
        $(this).toggleClass('flipped');
    });

}

export default showSliderPartners;