/* eslint-disable */
let mixitup = require('mixitup');

function controlCategoryTabs() {
    let mixer = mixitup( '.c-accordions__faq-items-wrapper', {
        load: {
            filter: '.faq_category_filter_1'
        },
        animation: {
            duration: 300
        }
    } );
}

export default controlCategoryTabs;