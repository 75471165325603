import MicroModal from 'micromodal';

function showAssetsFilesCards() {
    $('.o-asset-file-card__item-link').on('click', function(e) {
        e.preventDefault();
    });

    MicroModal.init({
        disableScroll: true,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
    });

}

export default showAssetsFilesCards;