function accordions() {
    // Function to toggle the accordion
    function toggleAccordion(element) {
        element.parent().toggleClass('is-active');
        element.toggleClass('active').next().slideToggle('fast');
    }

    // Click event handler
    $('.js-accordion').click(function (e) {
        e.preventDefault();
        toggleAccordion($(this));
    });

    // Keydown event handler for Enter key
    $('.js-accordion').keydown(function (e) {
        // 13 is the keycode for Enter
        if (e.keyCode === 13 || e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation(); // Stop the event from bubbling up
            toggleAccordion($(this));
        }
    });
}

export default accordions;
