/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import 'slick-carousel';
import navSide from "../../objects/nav/nav-side/nav-side";

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: true
};

function slider() {
    $('.js-slider-gallery').each(function() {
        setTimeout(function(){
            $('.js-slider-nav').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                adaptiveHeight: true,
                rows: 0,
                arrows: true,
                asNavFor: '.js-slider-for',
                focusOnSelect: true,
            });

            $('.js-slider-for').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                adaptiveHeight: true,
                rows: 0,
                infinite: true,
                asNavFor: '.js-slider-nav',
                fade: true,
            });
        }, 1000);
    });
    //
    $('.js-slider-alt-simple').each(function () {
        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: true,
            autoplaySpeed: 999000,
            speed: 350,
            center: true,
            cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
            centerPadding: '0',
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            centerMode: true,
            adaptiveHeight: true,
            appendDots: $child_slider.next().find('.c-scores__dots-wrapper-inner'),
            customPaging: function (slider, i) {
                var thumb = $(slider.$slides[i]).attr('data-thumb');
                return '<span class="c-scores__pagination-text">' + thumb + '</span>';
            }
        });
        $child_slider.not('.slick-initialized').slick(child_settings);
    });
    //
    // $('.js-slider-quote').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         arrows: true,
    //         autoplaySpeed: 10000,
    //         speed: 450,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         rows: 0,
    //         centerMode: true,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         responsive: [{
    //             breakpoint: 900,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false
    //             }
    //         }]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    // });
    //
    $('.js-slider-type-default').each(function () {
        const $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: slider_dots,
            autoplaySpeed: 10000,
            speed: 450,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            prevArrow: $child_slider.next().find('.js-slider-prev'),
            nextArrow: $child_slider.next().find('.js-slider-next'),
            responsive: [{
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }]
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });
    $('.js-apartment-photo-gallery').each(function () {
        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: false,
            autoplay: false,
            speed: 350,
            center: true,
            cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
            centerPadding: '0',
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            prevArrow: $child_slider.next().find('.js-slider-prev'),
            nextArrow: $child_slider.next().find('.js-slider-next'),
            adaptiveHeight: true,
            appendDots: $child_slider.next().find('.c-scores__dots-wrapper-inner')
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });
    $('.js-simple-single-photo-gallery').each(function () {
        const $child_slider = $(this);
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            dots: true,
            arrows: false,
            autoplay: true,
            speed: 400,
            center: true,
            cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
            centerPadding: '0',
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            adaptiveHeight: true,
            appendDots: $next_slider.find('.c-photo-gallery-simple-single__dots-wrapper-inner')
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });

    $('.js-slider-post').each(function () {
        var $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        $child_slider.not('.slick-initialized').slick({
            dots: slider_dots,
            arrows: true,
            autoplaySpeed: 5000,
            infinite: false,
            autoplay: false,
            speed: 450,
            slidesToShow: 3,
            prevArrow: $next_slider.find('.js-slider-prev'),
            nextArrow: $next_slider.find('.js-slider-next'),
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });

    //
    // $('.js-slider-full-image').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         autoplaySpeed: 5000,
    //         lazyLoad: 'ondemand',
    //         speed: 450,
    //         rows: 0,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         center: true,
    //         slidesToShow: 1,
    //         centerMode: false,
    //         adaptiveHeight: false,
    //         responsive: [{
    //                 breakpoint: 992,
    //                 center: false,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }, {
    //                 breakpoint: 768,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //             // You can unslick at a given breakpoint now by adding:
    //             // settings: "unslick"
    //             // instead of a settings object
    //         ]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    // });
    //
    // $('.js-slider-images-small').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         autoplaySpeed: 5000,
    //         lazyLoad: 'ondemand',
    //         speed: 450,
    //         rows: 0,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         center: true,
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         centerMode: true,
    //         adaptiveHeight: false,
    //         responsive: [{
    //             breakpoint: 992,
    //             center: false,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         }, {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    // });
    //
    // $('.js-slider-simple').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         autoplaySpeed: 5000,
    //         lazyLoad: 'ondemand',
    //         speed: 450,
    //         rows: 0,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         center: true,
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         centerMode: true,
    //         adaptiveHeight: false,
    //         responsive: [{
    //             breakpoint: 992,
    //             center: false,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         }, {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    // });
    //
    // $('.js-slider-staff').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         autoplaySpeed: 5000,
    //         lazyLoad: 'ondemand',
    //         speed: 450,
    //         rows: 0,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         center: true,
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         centerMode: true,
    //         adaptiveHeight: false,
    //         responsive: [{
    //             breakpoint: 992,
    //             center: false,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         }, {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    // });
    //
    // $('.js-slider-location').each(function () {
    //     var $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     $child_slider.not('.slick-initialized').slick({
    //         dots: slider_dots,
    //         autoplay: true,
    //         autoplaySpeed: 5000,
    //         infinite: true,
    //         arrows: true,
    //         speed: 450,
    //         rows: 0,
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         pauseOnHover: true,
    //         prevArrow: $child_slider.parent().next().find('.js-slider-prev'),
    //         nextArrow: $child_slider.parent().next().find('.js-slider-next'),
    //         responsive: [{
    //                 breakpoint: 1000,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 1
    //                 }
    //             }, {
    //                 breakpoint: 800,
    //                 settings: {
    //                     arrows: false,
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //             // You can unslick at a given breakpoint now by adding:
    //             // settings: "unslick"
    //             // instead of a settings object
    //         ]
    //     });
    // })
    //
    // $('.js-slider-video').each(function () {
    //     var $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     $child_slider.not('.slick-initialized').slick({
    //         dots: slider_dots,
    //         autoplay: true,
    //         autoplaySpeed: 5000,
    //         infinite: true,
    //         arrows: true,
    //         speed: 450,
    //         rows: 0,
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         pauseOnHover: true,
    //         prevArrow: $child_slider.parent().next().find('.js-slider-prev'),
    //         nextArrow: $child_slider.parent().next().find('.js-slider-next'),
    //         responsive: [{
    //                 breakpoint: 1000,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 1
    //                 }
    //             }, {
    //                 breakpoint: 800,
    //                 settings: {
    //                     arrows: false,
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //             // You can unslick at a given breakpoint now by adding:
    //             // settings: "unslick"
    //             // instead of a settings object
    //         ]
    //     });
    // })
    //
    // $('.js-slider-hero').each(function () {
    //     const $child_slider = $(this);
    //     slider_dots = Boolean($child_slider.attr('data-dots'));
    //     const $next_slider = $child_slider.next();
    //     const child_settings = Object.assign(defaultSettings, {
    //         dots: slider_dots,
    //         // cssEase: 'linear',
    //         autoplaySpeed: 5000,
    //         speed: 450,
    //         rows: 0,
    //         infinite: true,
    //         prevArrow: $next_slider.find('.js-slider-prev'),
    //         nextArrow: $next_slider.find('.js-slider-next'),
    //         slidesToShow: 1,
    //         center: true,
    //         centerMode: false,
    //         adaptiveHeight: false,
    //         responsive: [{
    //                 breakpoint: 992,
    //                 // center: false,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }, {
    //                 breakpoint: 768,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //             // You can unslick at a given breakpoint now by adding:
    //             // settings: "unslick"
    //             // instead of a settings object
    //         ]
    //     });
    //
    //     $child_slider.not('.slick-initialized').slick(child_settings);
    //
    //     var myVideo = document.getElementById("video__bg_header");
    //     myVideo.play();
    // });

}

export default slider;