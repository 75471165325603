function clickActionOutsideAreaCitySelector( e ) {
    if (
        $( e.target ).is( $( '.js-city-selector' ) ) === false &&
        $( '.js-city-selector' ).has( e.target ).length === 0 &&
        $( e.target ).is( $( '.c-city-selector__list' ) ) === false &&
        $( '.c-city-selector__list' ).has( e.target ).length === 0
    )
    {
        $( '.c-city-selector__arrow' ).removeClass('c-city-selector__arrow--active');
        $( '.c-city-selector__list' ).removeClass('c-city-selector__list--open');
    }
}

function citySelector() {

    $('.js-city-selector').on( 'click', function (event) {
        event.preventDefault();

        $(this).find('.c-city-selector__arrow').toggleClass('c-city-selector__arrow--active');
        $(this).siblings('.c-city-selector__list').toggleClass('c-city-selector__list--open');
    });

    $( document ).on( 'click', function( e ) {
        if( $( '.c-city-selector__list' ).hasClass( 'c-city-selector__list--open' ) ) {
            clickActionOutsideAreaCitySelector( e );
        }
    });
}

export default citySelector;
