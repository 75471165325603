import $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader";

function mapContentBottom() {
  let map;
  let google;
  let markers = [];
  const $map = $(".js-map");
  const $locations = $map.children(".c-map-content-bottom__marker");
  const loader = new Loader({
    apiKey: window.googleMapData.API_KEY,
    version: "3.3.0",
    libraries: ["places", "geometry"],
  });

  // const map = new google.maps.Map($map[0], mapArgs);

  /* init */
  _createMap($map[0]);
  _createMarkers($locations, markers, map);

  /* functions */
  function _createMap(el, args) {
    loader
      .load()
      .then((globalGoogle) => {
        google = globalGoogle;
        if (!el.dataset.lat || !el.dataset.lng) {
          throw new Error("No valid center coordinates are provided");
        }
        map = new google.maps.Map(el, {
          zoom: el.dataset.zoom ? parseInt(el.dataset.zoom, 10) : 14,
          center: new google.maps.LatLng(
            parseFloat(el.dataset.lat),
            parseFloat(el.dataset.lng)
          ),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
          streetViewControl: false,
          styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  color: "#f2f2f2",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 45,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  color: "#46bcec",
                },
                {
                  visibility: "on",
                },
              ],
            },
          ],
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  }

  function _createMarkers($els, markers, map) {
    $els.each(function () {
      const $el = $(this);
      const icons = _getIconsFromMarker($el);
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng($el.data("lat"), $el.data("lng")),
        map: map,
        icon: icons.inactive,
        icons: icons,
        infoWindow: new google.maps.InfoWindow(),
        id: $el.data("id"),
      });

      markers.push(marker);

      if ($el.html()) {
        marker.infoWindow.setContent($el.html());
      }

      google.maps.event.addListener(marker, "click", function () {
        _deselectAllMarkers();
        _setSelectedMarker(marker.id);

        if ($el.html()) {
          marker.infoWindow.open(map, marker);
        }

        google.maps.event.addListener(marker, "click", function () {
          _deselectAllMarkers();
          _setSelectedMarker(marker.id);

          if (!marker.open && $el.html()) {
            marker.infoWindow.open(map, marker);
            var iw_container = $(".gm-style-iw").parent();
            iw_container.stop().hide();
            iw_container.fadeIn(850);
            marker.open = true;
          } else {
            marker.infoWindow.close();
            _deselectAllMarkers();
            marker.open = false;
          }
        });

        google.maps.event.addListener(map, "click", function () {
          markers.map((marker) => {
            marker.setIcon(marker.icons.inactive);
            marker.infoWindow.close();
          });
          marker.open = false;
        });
      });
    });
  }

  function _getIconsFromMarker($el) {
    const icons = {
      active: $el.data("active") ? $el.data("active") : $map.data("active"),
      inactive: $el.data("inactive")
        ? $el.data("inactive")
        : $map.data("inactive"),
    };

    return icons;
  }

  function _deselectAllMarkers() {
    markers.map((marker) => {
      marker.setIcon(marker.icons.inactive);
      marker.infoWindow.close();
    });
  }

  function _setSelectedMarker(id) {
    markers.map((marker) => {
      if (marker.id === id) {
        marker.setIcon(marker.icons.active);
        map.panTo(marker.getPosition());
      }
      return marker;
    });
  }
}

export default mapContentBottom();
