import '../../../static/vendors/lightgallery/js/lightgallery.min';
import '../../../static/vendors/lg-thumbnail/lg-thumbnail.min';
import '../../../static/vendors/lg-fullscreen/lg-fullscreen.min';
import MicroModal from 'micromodal';


function showFeaturedDeals() {
    $( '.c-featured-deals__gallery-wrapper' ).lightGallery( {
        thumbnail : true,
        download: false
    } );

    $( '.c-featured-deals__offer-item-floorplan-thumbnail' ).lightGallery( {
        loop: false,
        download: false,
        counter: false,
        controls: false
    } );

    $('.js-featured-modal-open').click(function(){

        // MicroModal.init({
        //     onShow: modal => $(document.body).addClass("h-featured-modal--open"), // [1]
        //     onClose: modal => $(document.body).addClass("h-featured-modal--close"), // [2]
        // });

        const location   = $(this).attr("data-location");
        const price      = $(this).attr("data-price");
        const bed        = $(this).attr("data-bedroom");
        const sqft       = $(this).attr("data-sqft");
        const bath       = $(this).attr("data-bathroom");
        const id         = $(this).attr("data-id");
        const date_start = $(this).attr("data-datestart");
        const date_end   = $(this).attr("data-dateend");

        console.log(location + " " + price + " " + bed + " " + sqft + " " + bath + " " + id + " " + date_start + " " + date_end);

        function DoPrevent(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        MicroModal.show('modal-featured-form', {
            onShow: modal => {
                $(document.body).addClass("h-featured-modal--open");
                $(document.body).removeClass("h-featured-modal--close");
                window.onModalLoad(location, price, bed, sqft, bath, id, date_start, date_end);

                // Added to prevent user from clicking get started button when modal is open
                $('.l-header__get-started-btn').on('click', DoPrevent);
            },
            onClose: modal => {
                setTimeout(function(){
                    $(document.body).removeClass("h-featured-modal--open");
                    $(document.body).addClass("h-featured-modal--close");

                    // This ensures that user doesn't accidentally click get started button when closing popup
                    $('.l-header__get-started-btn').off('click', DoPrevent);
                }, 1000);
                window.onModalClose();
            },
        });


    });

    // $( '[data-fancybox^="featured-deals-form-modal-"]' ).fancybox( {
    //     arrows: false,
    //     keyboard: false,
    //     baseClass: "featured-deals-form-modal",
    //     infobar: false,
    //     hash: false,
    //     onStart(){
    //         console.log('dssd');
    //     }
    // } );

    $( '.c-featured-deals__item-copy-url' ).on( 'click', function( e ) {
        e.preventDefault();

        let currentUrl = window.location.href;

        if ( currentUrl.split( '#' )[ 1 ] != '' ) {
            currentUrl = currentUrl.split( '#' )[ 0 ];
        }

        let copyAnchorHref = $( this ).attr( 'href' );
        let fullUrl = currentUrl + copyAnchorHref;
        let temp = $( '<input>' );

        $( 'body' ).append( temp );
        temp.val( fullUrl ).select();
        document.execCommand( 'copy' );
        temp.remove();

        $( '.c-featured-deals__item-url-copied' ).addClass( 'c-featured-deals__item-url-copied--fly-out' );

        setTimeout(function(){
            $( '.c-featured-deals__item-url-copied' ).removeClass( 'c-featured-deals__item-url-copied--fly-out' );
        }, 1000);
    } );

    $( window ).on( 'load', function () {
        if ( typeof window.location.href.split( '#' )[1] !== 'undefined' ) {
            let urlHash = window.location.href.split( '#' )[1];

            $( 'html, body' ).animate( {
                scrollTop: $( '#' + urlHash ).offset().top
            }, 1000 );
        }
    } );
}

export default showFeaturedDeals;