/* eslint-disable */

import jQuery from "jquery";
import "../style.scss";

import scrollToTop from "../components/objects/scroll-to-top/scroll-to-top";
import searchBar from "../components/objects/search/search-bar/search-bar";
import accordions from "../components/objects/accordion/accordions";
import navMob from "../components/objects/nav/nav-mobile/nav-mobile";
import navSide from "../components/objects/nav/nav-side/nav-side";
import mapContentBottom from "../components/blocks-zone-yellow/map-content-bottom/map-content-bottom";
import slider from "../components/blocks-zone-yellow/slider/slider.js";
import socialShare from "../components/objects/social/social-share/social-share";
import citySelector from "../components/objects/city-selector/city-selector";
import slideOutBasic from "../components/objects/slide-outs/slide-out-basic/slide-out-basic";
import slideOutApartment from "../components/objects/slide-outs/slide-out-apartment/slide-out-apartment";
import showAssetsFilesCards from "../components/blocks-zone-yellow/assets-files-cards/assets-files-cards";
import showRewardsProgram from "../components/blocks-zone-yellow/rewards-program/rewards-program";
import showFeaturedDeals from "../components/blocks-zone-yellow/featured-deals/featured-deals";
import showSliderTestimonialsOneMiddle from "../components/blocks-zone-yellow/testimonials/testimonials";
import showSliderTestimonialsThreeMiddle from "../components/blocks-zone-yellow/testimonials-mini-quotes/testimonials-mini-quotes";
import showSliderPartners from "../components/blocks-zone-yellow/partners/partners";
import ctaFull from "../components/objects/cta/cta-full/cta-full";
import {
  hamburger,
  hamburgerClose,
} from "../components/objects/nav/hamburger/hamburger";

import filter from "../components/blocks-zone-none-repeater/filter/filter";
import filterCareers from "../components/blocks-zone-none-repeater/career-filter/career-filter";
import filterControlRedux from "../components/objects/filter/filter-control-redux/filter-control-redux";
import controlCollapsable from "../components/objects/filter/filter-control-checkbox/filter-control-collapsable";
import controlCategoryTabs from "../components/objects/filter/filter-control-category-tabs/filter-control-category-tabs";
import scrollToAnchor from "./custom/scroll/scroll-to-anchor";
import parallaxScroll from "./custom/parallax/parallaxscroll";
import headRoom from "./custom/headroom/headroom";

/** Responsive Images Polyfill */
import "picturefill";

/** Lazyload Images */
import "lazysizes/plugins/bgset/ls.bgset.js";
import "lazysizes";
// import './custom/lazysizes-slick/lazysizes-slick'; (TODO: use this)

// Setup lazysizes widths for bg images:
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
  "--small": "(max-width: 544px)",
  "--medium": "(max-width: 992px)",
  "--large": "(max-width: 99999px)",
};

// Reveal CSS animation as a user scrolls down a page: https://github.com/matthieua/WOW
import WOW from "wow.js/dist/wow.js";
new WOW().init();

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order

const app = {
  ctaFull,
  hamburger,
  scrollToTop,
  slider,
  controlCollapsable,
  navSide,
  mapContentBottom,
  filter,
  filterControlRedux,
  filterCareers,
  controlCategoryTabs,
  accordions,
  searchBar,
  citySelector,
  headRoom,
  slideOutApartment,
  slideOutBasic,
  socialShare,
  showAssetsFilesCards,
  showFeaturedDeals,
  showRewardsProgram,
  showSliderTestimonialsOneMiddle,
  showSliderTestimonialsThreeMiddle,
  showSliderPartners,
};

let calledModulesNames = [];
app.instantiate = function (elem) {
  const $this = $(elem);
  const module = $this.attr("data-module");

  if (module === undefined) {
    throw 'Module not defined (use data-module="")';
  } else if (module in app) {
    if ($this.attr("data-initialized") === "true") return;
    new app[module](elem);
    $this.attr("data-initialized", true);
  } else {
    throw "Module '" + module + "' not found";
  }
};

jQuery(document).ready(function () {
  scrollToAnchor($);
  navMob($);

  // Component functions that should only run if the component exists in the ajax container or page.
  $("[data-module]").each(function () {
    if ($.inArray($(this).data("module"), calledModulesNames) == -1) {
      app.instantiate(this);

      calledModulesNames.push($(this).data("module"));
    }
  });

  if (window.location.href.indexOf("utm_") > -1) {
    // UTM Source
    let init = window.location.href.split("utm_source=")[1];
    const utm_source = (init && init.split("&")[0]) || null;
    // UTM Medium
    init = window.location.href.split("utm_medium=")[1];
    const utm_medium = (init && init.split("&")[0]) || null;
    // UTM Campaign
    init = window.location.href.split("utm_campaign=")[1];
    const utm_campaign = (init && init.split("&")[0]) || null;
    // UTM Term
    init = window.location.href.split("utm_term=")[1];
    const utm_term = (init && init.split("&")[0]) || null;
    // UTM Content
    init = window.location.href.split("utm_content=")[1];
    const utm_content = (init && init.split("&")[0]) || null;
    // Referral Id
    init = window.location.href.split("referral_id=")[1];
    const referral_id = (init && init.split("&")[0]) || null;
    // Agent Id
    init = window.location.href.split("agent_id=")[1];
    const agent_id = (init && init.split("&")[0]) || null;
    localStorage.setItem(
      "analyticsData",
      JSON.stringify({
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        referral_id: (referral_id && referral_id.length === 7) || null,
        agent_id: (agent_id && agent_id.length <= 7) || null,
      })
    );
  } else {
    return null;
  }
});

jQuery(window).scroll(function () {
  parallaxScroll(jQuery);
});
