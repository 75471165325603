import "slick-carousel";

let slider_dots;

function showSliderTestimonialsThreeMiddle() {
  $(".js-slider-testimonials-three-middle").each(function () {
    let $child_slider = $(this);
    slider_dots = Boolean($child_slider.attr("data-dots"));
    $child_slider.not(".slick-initialized").slick({
      dots: false,
      arrows: false,
      autoplaySpeed: 0,
      infinite: true,
      autoplay: true,
      speed: 8000,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });
}

export default showSliderTestimonialsThreeMiddle;
