import 'slick-carousel';

let slider_dots;

function showSliderTestimonialsOneMiddle() {
    $('.js-slider-testimonials-one-middle').each(function () {
        let $child_slider = $(this);
        slider_dots = Boolean($child_slider.attr('data-dots'));
        $child_slider.not('.slick-initialized').slick({
            dots: true,
            arrows: false,
            autoplaySpeed: 5000,
            infinite: false,
            autoplay: false,
            speed: 450,
            slidesToShow: 1
        });
    });
}

export default showSliderTestimonialsOneMiddle;