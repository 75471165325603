/* eslint-disable */
function clickActionOutsideAreaFilter( event, componentClass ) {
    if (
        $( event.target ).is( `.${componentClass}__items-wrapper` ) === false &&
        $( `.${componentClass}__items-wrapper` ).has( event.target ).length === 0
    )
    {
        let filterButton = $( `.${componentClass}__toggler` );

        if ( filterButton.hasClass( `${componentClass}__toggler--open` ) ) {
            filterButton.trigger( 'click' );
        }

        if (
            $( event.target ).is( `.${componentClass}__list` ) === false &&
            $( `.${componentClass}__list` ).has( event.target ).length === 0
        )
        {
            $( `.${componentClass}__open-button` ).removeClass( `${componentClass}__open-button--is-open` );
            $( `.${componentClass}__list` ).slideUp( 200 );
        }
    }
}

function controlCollapsable() {
    const componentClassName   = 'c-filter-control-multi';
    const slideOutAptClassName = 'c-slide-out-apartment';

    $('.js-filter-controls-reset').on( 'click', function (e) {
        $(`.${componentClassName}__open-button`).removeClass(`${componentClassName}__open-button--is-open`);
        $(`.${componentClassName}__list`).slideUp( 200 );
    });

    $(`.${componentClassName}__btn`).on( 'click', function(e) {
        var $el = $(this).parents(`.${componentClassName}__content`);
        var $label = $el.parent().find(`.${componentClassName}__label`);

        //add teimout see we give mixitup enought time to add the class
        setTimeout(() => {
            if ($el.find('.mixitup-control-active').length !== 0) {
                $label.text('Selected');
            } else {
                $label.text('Filter');
            }

        }, 100);
    });

    $( window ).resize( function(){
        if ( window.innerWidth >= 992 ) {
            $( `.${componentClassName}__content` ).css( 'display', 'flex' );
        } else if ( window.innerWidth < 992 && $( `.${componentClassName}__content` ).css( 'display' ) === 'flex' ) {
            $( `.${componentClassName}__content` ).css( 'display', 'none' );

            $( `.${componentClassName}__toggler` ).removeClass( `${componentClassName}__toggler--open` );

            if( $( `.${componentClassName}__toggler` ).children( 'i' ).hasClass( 'fa-times-circle-o' ) ) {
                $( `.${componentClassName}__toggler-text` ).text( 'Filter' );
                $( `.${componentClassName}__toggler` ).children( 'i' ).removeClass( 'fa-times-circle-o' ).addClass( 'fa-filter' );
            }
        }
    } );

    $( `.${componentClassName}__toggler` ).on( 'click', function() {
        $ ( this ).toggleClass( `${componentClassName}__toggler--open` );

        $( this ).siblings( `.${componentClassName}__content` ).slideToggle();

        $( this ).children( `.${componentClassName}__toggler-text` ).text( function( index, value ){
            return value === 'Filter' ? 'Close Filter' : 'Filter';
        });

        $( this ).children( 'i' ).toggleClass( 'fa-filter fa-times-circle-o' );
    } );

    $ ( `.${componentClassName}__open-button` ).on( 'click', function( e ) {
        e.preventDefault();

        if ( $( '.c-city-selector__list' ).hasClass( 'c-city-selector__list--open' ) ) {
            $( '.js-city-selector' ).trigger( 'click' );
        }

        $( `.${slideOutAptClassName}` ).removeClass( `${slideOutAptClassName}--active` );
        $( 'body' ).removeClass( 'h-scroll-lock' );

        if ( $( `.${slideOutAptClassName}__close-btn` ).hasClass( `${slideOutAptClassName}__close-btn--open` ) ) {
            $( `.${slideOutAptClassName}__close-btn` ).toggleClass( `${slideOutAptClassName}__close-btn--open` );
        }

        if ( $( this ).hasClass( `${componentClassName}__open-button--is-open` ) ) {
            $(`.${componentClassName}__open-button`).removeClass(`${componentClassName}__open-button--is-open`);
            $(`.${componentClassName}__open-button`).siblings(`.${componentClassName}__list`).slideUp(200);
        } else {
            $( `.${componentClassName}__open-button` ).not( $( this ) ).removeClass( `${componentClassName}__open-button--is-open` );
            $( `.${componentClassName}__open-button` ).not( $( this ) ).siblings( `.${componentClassName}__list` ).slideUp(200);

            $( this ).addClass(`${componentClassName}__open-button--is-open`);

            $( this ).siblings(`.${componentClassName}__list`).slideDown(200);
        }
    } );

    $( document ).on( 'click', function( e ) {
        clickActionOutsideAreaFilter( e, componentClassName );
    });

    // This document.click event must fire on iOS Safari
    /*document.addEventListener('touchstart', function( e ) {
        clickActionOutsideAreaFilter( e, componentClassName );
    });*/
}

export default controlCollapsable;